html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}
body {
    background-image: url('wedding2.png');
    background-repeat: no-repeat;
    background-size: cover;
    height:100vh;
    margin: 0;
    padding: 0;
}

.menu {
    position: relative;
    display: flex;
    left:60%;
    gap: 8%;
    right: 0;
    top: 15px;
    
}
.contact-image3{
    position: relative;
    top:-510px;
    width: 120px;
   }

.item1, .item2, .item3 {
    letter-spacing: 5px;
    padding: 13px;
    font-size: 16px;
    font-family:  sans-serif;
    color: rgb(209, 179, 197);
    border-bottom: 2px solid rgb(216, 177, 198);
}

.item1:hover, .item2:hover, .item3:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(158, 135, 149);
    cursor:pointer;
    transition: 1s;
}
.item01, .item02, .item03{
    text-decoration: none;
}
.title {
    position: relative;
    left: 10%;
    top: 240px;
    font-size: 90px;
    letter-spacing: 8px;
    color: rgb(240, 229, 229);
    font-family: sans-serif;
}

.title:hover {
    cursor: default;
}

.content {
    position: relative;
    color: rgb(188, 169, 169);
    top: 60px;
    left: 170px;
    text-align: start;
    font-family: sans-serif;
}

.content1 {
    position: relative;
    font-size: 30px;
    color: rgb(225, 217, 224);
}

.entry {
    position: relative;
    left: 20px;
    font-family: sans-serif;
}

.content2 {
    position: relative;
    top: 320px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.insta {
    position: relative;
    top: 360px;
    width: 100%;
    display: flex;
    align-items: center;
}

.arrow1, .arrow2 {
    cursor: pointer;
    color:  rgb(178, 144, 184);
}

.slider {
    width: 100%;
    overflow: hidden;
}

.slide-container {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    flex-shrink: 0;
    width: 100%;
}

.posts {
    border: 3px solid rgb(178, 144, 184);
}

.instatitle {
    display: flex;
    position: relative;
    top: 720px;
    left: 35px;
    color: rgb(224, 186, 186);
    font-family: sans-serif;
}

.instaicon {
    position: relative;
    top: 20px;
    left: 5px;
}

.hrline, .hrline1 {
    color: black;
    background-color: black;
    position: relative;
}

.hrline {
    top: 700px;
}

.hrline1 {
    top: 740px;
}

.hashtags {
    position: relative;
    top: 690px;
    left: 33px;
    color: rgb(178, 144, 184);
}

.card1, .card2, .card3 {
    
    margin: 0;
    padding: 0;
    top: 200px;
    background-color: rgb(202, 144, 196);
    transition: padding 1.4s ease;
    overflow: hidden;
    border-radius: 30px;
    border:solid 2px  rgb(178, 144, 184);
    width:450px;
}

.card1:hover {
    padding-right: 250px;
    transition: padding 0.3s ease;
    cursor:pointer;
}
.card2:hover {
    padding-right: 250px;
    transition: padding 0.3s ease;
    cursor:pointer;
}

.card3:hover {
    padding-left: 250px;
    transition: padding 0.3s ease;
    cursor:pointer;
}

.overlay, .overlay1, .overlay2 {
    position: relative;
    top: 230px;
    font-size: 60px;
    color:rgb(247, 246, 246);
    font-family: 'Times New Roman', Times, serif;
}

.overlay {
    left: 25%;
}
.overlay1 {
    left: 24%;
}

.overlay2 {
    left: 36%;
}

.cards {
    position:relative;
    display: flex;
    left:30px;
    height: 0px;
    top:150px;
    gap: 30px;
    align-items: center;
}

.text1, .text2, .text3 {
    color: rgb(0, 0, 0);
    font-size: 11px;
    text-align: center;
    position: relative;
    opacity: 0;
    pointer-events: none;
    font-family: sans-serif;
    letter-spacing: 0.5px;
    transition: opacity 0.1s ease, transform 1.1s ease;
}

.text1 {
    top: -220px;
    left: 230px;
}

.text2 {
    top: -230px;
    left: 230px;
}

.text3 {
    top: -200px;
    right: 225px;
}

.one:hover .text1,
.two:hover .text2,
.three:hover .text3 {
    opacity: 1;
}

.one, .two, .three {
    margin: 0;
    padding: 0;
    border: 0;
    height: 0;
}
.one:hover .card1,
.two:hover .card2,
.three:hover .card3{
    opacity: 0.8;
    transition: 0.6s;
}
.promises{
    display: flex;
    position: relative;
    top: 320px;
    left: 35px;
    color: rgb(224, 186, 186);
    font-family: sans-serif;
}
.picon{
    position: relative;
    top: 20px;
    left: 5px;
}
.hrline2{
    top:300px;
    color: black;
    background-color: black;
    position: relative;
}
.footer0{
    background-color:rgb(0, 0, 0);
    padding:10px;
    font-family:sans-serif;
    text-align: center;
    font-size: 20px;
    color: rgb(224, 186, 186);
    opacity: 0.6;
    position: relative;
    top:800px;
}
.back{
    background-color: black;
    width:100%;
    height:150px;
    position: relative;
    top:-100px;
    z-index: -1;
    opacity:0.6;
    border-bottom: solid 2px rgb(224, 186, 186);
}
/* Responsive styles */
@media (max-width: 1200px) {
    .title {
        font-size: 70px;
        left: 5%;
    }

    .content {
        left: 100px;
    }

    .overlay, .overlay1, .overlay2 {
        font-size: 50px;
    }
}

@media (max-width: 992px) {
    .menu {
        left: 50%;
        gap: 5%;
    }

    .title {
        font-size: 60px;
        top: 200px;
    }

    .content {
        left: 80px;
        top: 50px;
    }

    .content2 {
        top: 280px;
    }

    .overlay, .overlay1, .overlay2 {
        font-size: 45px;
        top: 200px;
    }

    .instatitle {
        top: 600px;
    }

    .hrline, .hrline1 {
        top: 600px;
    }

    .hashtags {
        top: 600px;
    }

    .cards {
        top: 120px;
    }

    .footer0 {
        top: 700px;
    }
}

@media (max-width: 768px) {
    .menu {
        left: 40%;
        gap: 3%;
    }

    .title {
        font-size: 50px;
        top: 150px;
    }

    .content {
        left: 60px;
        top: 40px;
    }

    .content2 {
        top: 250px;
        flex-direction: column;
    }

    .overlay, .overlay1, .overlay2 {
        font-size: 40px;
        top: 180px;
    }

    .instatitle {
        top: 500px;
    }

    .hrline, .hrline1 {
        top: 500px;
    }

    .hashtags {
        top: 500px;
    }

    .cards {
        top: 100px;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .footer0 {
        top: 600px;
    }
}

@media (max-width: 576px) {
    .menu {
        flex-direction: column;
        left: 60%;
        gap:0px;
    }

    .title {
        font-size: 40px;
        top: 100px;
    }

    .content {
        left: 40px;
        top: 30px;
    }

    .content2 {
        top: 220px;
    }

    .overlay, .overlay1, .overlay2 {
        font-size: 45px;
        top: 380px;
        left: 12%;
        z-index:20;
    }
    .overlay{
        position: relative;
        left:70px;
    }
    .overlay2{
        position: relative;
        left:96px;
    }
    .overlay,.overlay1,.overlay2:hover{
        cursor: default;
    }
    .instatitle {
        top: -300px;
        left: 20px;
    }

    .hrline, .hrline1 {
        top: -320px;
    }

    .hashtags {
        top: -330px;
        left: 20px;
        font-size: 14px;;
    }

    .cards {
        flex-direction: column;
        gap:300px;
        position: relative;
        top: -600px;
    }
    .card1,.card2,.card3{
        position:relative;
        left:-30px;
        height:220px;
        width:350px;
    }
    .card2{
        position:relative;
        left:-30px;
        height:220px;
        width:350px;
    }
    .card1:hover{
      opacity:1;
      padding-right: 0;
    }
    .text1{
      visibility: hidden;
    }
    .card2:hover{
        padding-right: 0;
    }
    .text2{
        visibility: hidden;
    }
    .card3:hover{
        padding-left: 0;
        
    }
    .text3{
        visibility: hidden;
    }
    .one:hover .card1,
    .two:hover .card2,
    .three:hover .card3{
         opacity: 1;
         transition: 0.6s;
         cursor:default;
        }
    .footer0 {
        top: 600px;
    }
    .instagram{
        position: relative;
        top:900px;
    }
    .insta{
        position: relative;
        top:-160px;
    }
    .back{
            background-color: black;
            width:100%;
            height:350px;
            position: relative;
            top:-300px;
            z-index: -1;
            opacity:0.6;
            border-bottom: solid 2px rgb(224, 186, 186);
    }
    .contact-image3{
        position: relative;
        top:-750px;
        left:30px;
        width: 180px;
       }
       .title{
        font-size: 40px;
        top:-200px;
       }
       .content1{
        position: relative;
        font-size: 18px;
        left:20px;
        text-align: center;
        top:-20px;
       }
       .content{
        font-size: 12px;
        left:-20px;
        text-align: center;  
        top:-350px;     
    }
    .promises{
        left:40px;
        top:-280px;
    }
    .hrline2{
        top:-300px;
    }
}
@media (width : 412px) {
    .promises{
        left:65px;
        top:-290px;
    }
}