.loc {
    color: rgb(224, 186, 186);
    position: relative;
    left: 480px;
    top: -540px;
    font-family: sans-serif;
    display: flex;
    font-size: 25px;
    padding: 40px;
    padding-top: 300px;
    padding-bottom: 225px;
    background: black;
    text-align: top;
    opacity: 0.8;
    transition: opacity 0.8s ease; /* Add transition for smooth opacity change */
}
.title3{
    color: rgb(224, 186, 186);
    position: relative;
    left: 750px;
    top: -950px;
    font-family: sans-serif;
    display: flex;
    font-size: 20px;
}
.title2{
    color: rgb(224, 186, 186);
    position: relative;
    left: 1150px;
    top: -1050px;
    font-family: sans-serif;
    display: flex;
    font-size: 20px;
}
.click3{
    position: relative;
    top:100px;
    left:-315px;
    color: rgb(224, 186, 186);
    font-family: sans-serif;
    border-radius: 55px;
    border: solid 1px  rgb(224, 186, 186);
    padding:2px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top:18px;
    text-align: center;
    letter-spacing: 2px;
}
.click4{
    position: relative;
    top:100px;
    left:-286px;
    color: rgb(224, 186, 186);
    font-family: sans-serif;
    border-radius: 55px;
    border: solid 1px  rgb(224, 186, 186);
    padding:2px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top:18px;
    text-align: center;
    letter-spacing: 2px;
}
.phonei1{
    position: relative;
    left:-40px;
}
.phonei2{
    position: relative;
    left:-40px;
}
.click4, .click3:hover{
    cursor:text;
}
.contactInfo{
    opacity: 1;
    pointer-events: none; /* Disable interaction */
    transition: opacity 0.3s ease-in-out;
}
.contactInfo.visible{
    opacity: 1;
    pointer-events: auto;
}
.locicon {
    position: relative;
    top: 40px;
}
.conticon1{
    position: relative;
    top:22px;
}
.conticon2{
    position: relative;
    top:22px;
}
.title3 , .title2:hover{
    cursor: default;
}
.loct {
    color: rgb(224, 186, 186);
    position: relative;
    left: 20px;
    font-family: sans-serif;
}

.maps {
    position: relative;
    left: 20px;
    padding: 40px;
    background-color: black;
    padding-left: 300px;
    margin: 0;
    border: 0;
    opacity: 0.8;
    pointer-events: none;
    transition: opacity 0.8s ease;
}
.maps.visible {
    opacity: 0.8 !important;
    pointer-events: auto; /* Enable interaction */
  }
.vertical-line {
    border-left: 2px solid rgb(224, 186, 186); /* Set the color and width of the line */
    height: 550px; /* Set the height of the line */
    margin: 0 20px; /* Add some margin for spacing */
    position: relative;
    left: 20px;
}

.container {
    display: flex;
    align-items: center;
}

.container1 {
    display: flex;
    align-items: center;
    position: relative;
    top: -400px;
    left: -100px;
}

.cont {
    color: rgb(224, 186, 186);
    position: relative;
    left: 1200px;
    top: -780px;
    font-family: sans-serif;
    display: flex;
    font-size: 25px;
    cursor: pointer; 
}


.conticon {
    position: relative;
    top: 50px;
    left: 10px;
}

.cont1 {
    color: rgb(224, 186, 186);
    position: relative;
    left: 20px;
    font-family: sans-serif;
    display: flex;
    font-size: 25px;
    cursor: pointer; 
}
.locicon1 {
    position: relative;
    top: 37px;
}

.info {
    position: relative;
    top: 10px;
}
.visible {
    opacity: 0.8!important;
  }
  .click{
    font-size: 15px;
    color: rgb(224, 186, 186);
    font-family: sans-serif;
    background-color: black;
    border-radius: 55px;
    text-align: center;
    position: relative;
    top:-50px;
    letter-spacing: 5px;
    padding:2px;
    left:10px;
    border: solid 1px  rgb(224, 186, 186);;
  }
  .click1{
        font-size: 15px;
        color: rgb(224, 186, 186);
        font-family: sans-serif;
        background-color: black;
        border-radius: 55px;
        text-align: center;
        position: relative;
        top:50px;
        letter-spacing: 5px;
        left:60px;
        padding:2px;
        padding-left: 80px;
        padding-right: 80px;
        top:5px;
        border: solid 1px  rgb(224, 186, 186);
  }
  .hrline01{
    position: relative;
    top:-550px;
    color:rgb(224, 186, 186) ;
    background-color: rgb(224, 186, 186);
  }
  .contact-image {
   position: relative;
   top:-150px;
   width: 120px;
  }
  .footer{
    background-color:rgb(0, 0, 0);
    padding:10px;
    font-family:sans-serif;
    text-align: center;
    font-size: 20px;
    color: rgb(224, 186, 186);
    opacity: 0.6;
  }
  .contact-image1{
    width:150px;
    position:relative;
  }
  .letter{
    z-index: 2;
    position: relative;
    top:-300px;
    text-align: center;
  }
  .blank{
    background-color:rgb(0, 0, 0);
    height:60px;
    width:1100px;
    position:relative;
    left:270px;
    z-index: -1;
    top:-250px;
    border-radius: 20px;
    border:solid 2px  rgb(224, 186, 186);
    opacity:0.7;
  }
  .hrline5{
    position: relative;
    top:-550px;
  }
  .back1{
    background-color: black;
    width:100%;
    height:150px;
    position: relative;
    top:-300px;
    z-index: -1;
    opacity:0.6;
    border-bottom: solid 2px rgb(224, 186, 186);
}