.insta {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.slider {
    width: 95%;
    overflow: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
}
.slider::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

.slider {
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */
}
.slide-container {
    display: flex;
}

.slide {
    flex: 0 0 auto;
    scroll-snap-align: start;
}

.arrow1, .arrow2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
}

.arrow1 {
    left: 10px;
}

.arrow2 {
    right: 10px;
}

@media (max-width: 576px) {
    .slide {
        width: 100% !important; /* Full width for slides on small screens */
    }
    .slider{
        width:85% !important;
    }
    .arrow2{
        position: absolute;
        right:-2px;
    }
    .arrow1{
        left:6px;
    }
}
