.masonry-layout{
    padding:40px;
    position:relative;
    top:20px;
    bottom:20px;
    z-index:20;
}
.photo-wrapper {
    padding: 5px;
    transition: transform 0.3s ease, z-index 0.3s ease;
    position:relative;
    margin:5px;
}

.photo-wrapper:hover {
    z-index: 10;
    cursor: pointer;
}

.photo-wrapper img {
    transition: transform 0.3s ease;
    border: 3px solid rgb(178, 144, 184);
    border-radius: 8px;
    object-fit: cover; 
}
.promises0{
    font-size: 50px;
    display: flex;
    position: relative;
    top: 20px;
    padding-left: 65px;
    color: rgb(224, 186, 186);
    font-family: sans-serif;
}
.picon1{
    position: relative;
    top: 80px;
    left: 5px;
}
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 3px rgb(224, 186, 186);;
    background-color: rgb(0, 0, 0);
  }
  
  video {
    max-width: 100%;
    height: 550px;
    border-radius: 10px;
  }

.photo-wrapper:hover img {
    transform: scale(1.1);
}
.contact-image2 {
    position: relative;
    top:-75px;
    width: 120px;
   }
.hrline3{
   position: relative;
}
.hrline4{
    position: relative;
    top:120px;
 }
.footer1{
    background-color:rgb(0, 0, 0);
    padding:10px;
    font-family:sans-serif;
    text-align: center;
    font-size: 20px;
    color: rgb(224, 186, 186);
    opacity: 0.6;
    position: relative;
    top:200px;
}
.heading{
    color: rgb(224, 186, 186);
    font-size: 30px;
    position: relative;
    left:50px;
    font-family: sans-serif;
}
.back2{
    background-color: black;
    width:100%;
    height:150px;
    position: relative;
    top:-225px;
    z-index: -1;
    opacity:0.6;
    border-bottom: solid 2px rgb(224, 186, 186);
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
    background: rgb(224, 186, 186);
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  
  .popup-image {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 8px;
  }
  
  .close-btn {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color:rgb(0, 0, 0);
  }
  